import React from "react"
import BasicLayout from "src/components/layout/BasicLayout/BasicLayout"
import SEO from "src/components/seo"
import DevelopmentPage from "../../../components/DevelopmentPage/DevelopmentPage"

const MobileDevelopment = () => (
  <BasicLayout stickyBtn={false}>
    <SEO title="Mobile App Development" />
    <DevelopmentPage
      pageTitle="Mobile App Development"
      imagePath="mobile-dev-intro.png"
    />
  </BasicLayout>
)

export default MobileDevelopment
